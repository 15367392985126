<template>
  <div class="page-content" v-if="product">
    <section class="my-3">
      <b-card class="mt-1">
        <validation-observer
          ref="createForm"
          v-slot="{ handleSubmit }"
        >
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col cols="3">
                <strong class="h6">Thumbnail</strong>
                <ui-component :component-data="product_thumbnail" class="border" />
              </b-col>
              <b-col cols="9">
                <validation-provider
                  name="Type"
                  v-slot="validationContext"
                  :rules="{ required: true}"
                >
                  <b-form-group
                    label="Type"
                    label-for="h-type"
                  >
                    <b-form-select 
                      v-model="product.product_type"
                      :options="type_options"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-type-feedback" 
                    />
                    <b-form-invalid-feedback id="input-type-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Amount"
                  v-slot="validationContext"
                  :rules="{ required: true}"
                >
                  <b-form-group
                    label="Amount"
                    label-for="h-amount"
                  >
                    <b-form-input 
                      v-model="product.amount"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-amount-feedback" 
                    />
                    <b-form-invalid-feedback id="input-amount-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Price"
                  v-slot="validationContext"
                  :rules="{ required: true}"
                >
                  <b-form-group
                    label="Price"
                    label-for="h-price"
                  >
                    <b-form-input 
                      v-model="product.price"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-price-feedback" 
                    />
                    <b-form-invalid-feedback id="input-price-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
export default {
  components:{
    ValidationProvider, ValidationObserver 
  },
  data(){
    return{
      product: null,
      isUpdating: false,
      isDeleting: false,
      type_options: [
        {text: "Gem", value: 1}
      ],
      product_thumbnail: {
        type: "image",
        value: null,
        meta: {
          alt: null,
          redirect_link: null
        }
      }
    }
  },
  computed: {
    productId() {
      return this.$route.params.product_id;
    },
  },
  watch:{
    "product_thumbnail.value": function (newVal){
      this.product.thumbnail = newVal
    } 
  },
  created(){
    this.getProduct();
  },
  methods:{
    async getProduct(){
      this.product = await service.get({id: this.productId});
      this.product_thumbnail.value = this.product.thumbnail;
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.product),
      });
      this.getProduct();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "product-list" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>
